import { template as template_71dd259e6bf74199b55fe24f1925c5ae } from "@ember/template-compiler";
const SidebarSectionMessage = template_71dd259e6bf74199b55fe24f1925c5ae(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
