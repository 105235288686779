import { template as template_e97c7d74366c4718af7ca6a41137f04d } from "@ember/template-compiler";
const FKLabel = template_e97c7d74366c4718af7ca6a41137f04d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
